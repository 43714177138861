<template>
  <div class="achievements__details__wrapper" id="my-achievements-details">
    <div class="ac__detail__container">
      <div class="ac__detail__container--heading">
        <h6 class="ac__detail__container--title">
          {{ awardDetail?.course?.display_name }}
          <img
            src="@/assets/images/achievements/detail/hyperlink.svg"
            alt="hyperlink"
            @click="openCourse"
            class="ac__detail__container--link"
            v-if="!isAuthenticated"
          />
        </h6>

        <LxpMenu
          :text="menuText"
          :items="menuItems"
          className="ac-detail__menu"
          @select="onSelect"
          :noCaret="true"
          :isMenuTextHTML="true"
          v-if="isAuthenticated"
        />
      </div>
      <div class="detail__bodyContainer">
        <LeftContainer :achievement="awardDetail" />
        <RightContainer />
      </div>
    </div>
  </div>
  <!-- </LayoutManager> -->
</template>
<script>
import { mapGetters } from "vuex";
import { LxpMenu } from "didactica";
// mixins
import downloadCopy from "./mixins/downloadCopy";
// components
import LeftContainer from "./LeftContainer.vue";
import RightContainer from "./RightContainer.vue";

export default {
  components: {
    LxpMenu,
    LeftContainer,
    RightContainer
  },
  mixins: [downloadCopy],
  computed: {
    ...mapGetters(["awardDetail"]),
    menuText() {
      return `<img src="${require("@/assets/images/achievements/list/menu.svg")}">`;
    },
    menuItems() {
      let arr = [
        {
          id: "about_course",
          label: this.$t("my_achievements.about_this_course"),
          active: false
        }
      ];
      if (this.awardDetail.download_url) {
        arr.push({
          id: "download",
          label: this.$t("general.download"),
          active: false
        });
      }
      return arr;
    }
  },
  methods: {
    navigateToLink(item) {
      this.$router.push(item.to);
    },
    onSelect(s) {
      if (s.id === "about_course") {
        this.$router.push({
          path: `/coursewares/${this.awardDetail.course.key}`
        });
      } else if (s.id === "download") {
        this.downloadAwards(this.awardDetail.download_url);
      }
    },
    openCourse() {
      let url = `${window.location.origin}/coursewares/${this.awardDetail.course.key}`;
      window.open(url, "_blank");
    }
  }
};
</script>

<style lang="scss" scoped>
.achievements__details__wrapper {
  padding: 10px 40px;
  .ac__details__breadcrumb {
    ::v-deep .breadcrumb {
      padding: 0.75rem 0;
      margin-bottom: 0;
    }
  }
  .ac__detail__container {
    .detail__bodyContainer {
      display: flex;
      align-items: flex-start;
      gap: 32px;
      align-self: stretch;
    }
    .ac__detail__container--heading {
      @include horizontal-space-between;
      margin-bottom: 16px;
      .ac__detail__container--title {
        @include subtitle-large;
        margin-bottom: 0;
      }
      .ac__detail__container--link {
        cursor: pointer;
        margin-left: 8px;
      }
    }
  }
}
[dir="rtl"] {
  .achievements__details__wrapper {
    .ac__detail__container {
      .ac__detail__container--heading {
        .ac__detail__container--link {
          margin: 0 8px 0 0;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.ac-detail__menu {
  .dropdown {
    &.show {
      .dropdown-toggle {
        background: $brand-primary-100 !important;
      }
    }
    .dropdown-toggle {
      background: transparent;
      padding: 0;
      height: 42px;
      width: 42px;
      border: none;
      @include flex-horizontal-center;
      &:hover,
      &:focus,
      &:active,
      &:focus-visible {
        border-radius: 100px;
        box-shadow: none;
        .lxp-menu--dropdown-button {
          padding: 0;
        }
      }
    }
  }
}
</style>
